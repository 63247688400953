import request from './request'

const api = {
	// 获取列表
	lists: '/materialcate/lists',
	// 添加
	add: '/materialcate/add',
	// 删除
	dele: '/materialcate/del',
	// 编辑
	edit: '/materialcate/update',
	// 详情
	info: '/materialcate/read',
}

export {
	request,
	api
}
